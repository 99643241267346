export const suggestions = [
  'digital nomad',
  'engineer',
  'dev',
  'senior',
  'exec',
  'javascript',
  'backend',
  'full stack',
  'front end',
  'ruby',
  'devops',
  'react',
  'node js',
  'golang',
  'php',
  'python',
  'product manager',
  'ios',
  'non tech',
  'c',
  'web dev',
  'c plus plus',
  'java',
  'admin',
  'angularjs',
  'design',
  'customer support',
  'aws',
  'marketing',
  'mobile',
  'sys admin',
  'css',
  'cloud',
  'sql',
  'architecture',
  'quality assurance',
  'react native',
  'saas',
  'html',
  'typescript',
  'android',
  'testing',
  'nodejs',
  'ruby on rails',
  'wordpress',
  'graphql',
  'infosec',
  'api',
  'ecommerce',
  'vue',
  'frontend',
  'product',
  'data science',
  'machine learning',
  'docker',
  'y combinator',
  'postgres',
  'rails',
  'developer',
  'linux',
  'analyst',
  'node',
  'meteor js',
  'mongodb',
  'sales',
  'go',
  'tech lead',
  'finance',
  'medical',
  'ux',
  'social media',
  'junior',
  'reactjs',
  'education',
  'game dev',
  'ui',
  'kubernetes',
  'redux',
  'tech',
  'git',
  'django',
  'serverless',
  'video',
  'startup',
  'scala',
  'health tech',
  'engineering',
  'laravel',
  'mysql',
  'recruiter',
  'swift',
  'infrastructure',
  'kotlin',
  'clojure',
  'objective c',
  'agile',
  'consulting',
  'copywriting',
  'professional services',
  'vuejs',
  'stats',
  'dba',
  'macos',
  'postgresql',
  'growth',
  'node dev',
  'angular',
  'fintech',
  'support',
  'back end',
  'big data',
  'technical support',
  'salesforce',
  'product management',
  'terraform',
  'azure',
  'artificial intelligence',
  'sre',
  'shopify',
  'software engineer',
  'elixir',
  'ambassador',
  'fullstack',
  'seo',
  'microservices',
  'open source',
  'unity',
  'html5',
  'js',
  'spring',
  'redis',
  'data',
  'ops',
  'symfony',
  'customer service',
  'software development',
  'gcp',
  'elasticsearch',
  'content creation',
  'b2b',
  'teaching',
  'tailwind',
  'customer success',
  'nextjs',
  'edtech',
  'jquery',
  'development',
  'ads',
  'writing',
  'manager',
  'part time',
  'web development',
  'microsoft',
  'lead',
  'monitoring',
  'content',
  'embedded',
  'software',
  'drupal',
  'content marketing',
  'gitlab',
  'qa',
  'security',
  'music',
  'strategic partnerships',
  'operations',
  'manual testing',
  'nlp',
  'online',
  'privacy',
  'analytics',
  'rest',
  'management',
  'data engineering',
  'apis',
  'celery',
  'communication skills',
  'clojurescript',
  'research',
  'database',
  'digital marketing',
  'branding',
  'flutter',
  'scss',
  'woocommerce',
  'k8s',
  'ror',
  'es6',
  'team lead',
  'demand generation',
  'app',
  'full time',
  'firebase',
  'account management',
  'tech support',
  'virtual assistant',
  'financial services',
  'zendesk',
  'technical',
  'd3js',
  'site reliability',
  'dataviz',
  'gaming',
  'user acquisition',
  'user training',
  'dashboard',
  'nosql',
  'contract',
  'enterprise',
  'crypto',
  'paas',
  'algorithms',
  'kafka',
  'airflow',
  'luigi',
  'aso',
  'video game',
  'webapp',
  'webrtc',
  'ssr',
  'tdd',
  'ci',
  'dataops',
  'travel',
  'rust',
  'head of growth',
  'outdoors',
  'mid level',
  'german',
  'bootstrap',
  'user generated content',
  'ai',
  'publishing',
  'datomic',
  'media',
  'lambda',
  'ember',
  'jenkins',
  'node senior',
  'photoshop',
  'node front end',
  'automation',
  'sketch',
  'cd',
  'rxjs',
  'remote job',
  'node angular',
  'chat',
  'user interface',
  'user experience',
  'developer tooling',
  'rest api',
  'product marketing',
  'start up',
  'rest apis',
  'mern',
  'figma',
  'healthcare tech',
  'fundraising',
  'data modeling',
  'redshift',
  'performance marketing',
  'streaming',
  'bigdata',
  'cloud native',
  'programming',
  'diverse community',
  'etl',
  'business intelligence',
  'sdk',
  'product designer',
  'growth hacking',
  'digital',
  'client services',
  'full stack engineer',
  'systems',
  'scrum',
  'mongo',
  'blockchain',
  'copywriter',
  'project coordinator',
  'visualization',
  'project management',
  'haskell',
  'dot net',
  'google analytics',
  'css3',
  'mobile development',
  'affiliate',
  'magento',
  're frame',
  'wp rocket',
  'e commerce',
  'chef',
  'email',
  'amazon',
  'ember js',
  'engineering management',
  'java spring boot',
  'ci cd',
  'xml',
  'rabbitmq',
  'nginx',
  'executive',
  'hiring',
  'nyc',
  'developer relations',
  'node full stack',
  'technical product manager',
  'apm',
  'node engineer',
  'technical documentation',
  'node sql',
  'robotics',
  'net',
  'english',
  'node api',
  'english teacher',
  'net developer',
  'account executive',
  'healthtech',
  'bus dev',
  'hackerone',
  'nodeshopify',
  'quality',
  'database administration',
  'scraping',
  'force com',
  'solidity',
  'ethereum',
  'agriculture',
  'amplify django',
  'aws ec2 lambda api gatewayecs',
  'ycombinator',
  'future of work',
  'breakout list',
  'ec2',
  'outdoor navigation app',
  'facebook',
  'advertisement',
  'yc',
  'powershell',
  'elk',
  'technical management',
  'insurance',
  'insurtech',
  'insuretech',
  'product owner',
  's3',
  'product development',
  'user support',
  'product vision',
  'payment gateways',
  'speech',
  'advocacy',
  'phoenix',
  'postresql',
  'team',
  'node react native',
  'javasript',
  'containers',
  'nocode',
  'appexchange',
  'scrum master',
  'support tickets',
  'detail oriented',
  'team player',
  'conflict resolution',
  'search engine optimization',
  'bookkeeping',
  'link building',
  'vue js',
  'postgresgl',
  'boostrap',
  'phython',
  'accounting',
  'application security',
  'webpack',
  'product development prioritisation',
  'snowflake',
  'ionic',
  'digitalocean',
  'technology',
  'bigtable',
  'bigquery',
  'apache',
  'distributed computing',
  'hadoop',
  'web app',
  'apache spark',
  'tall stack',
  'data pipelines',
  'livewire',
  'helpdesk',
  'engineer data engineer',
  'schema',
  'data warehouse',
  'vfx',
  '3d',
  'interface',
  'logo design',
  'creative director',
  'design director',
  'software engineering',
  'mobile app',
  'plugin',
  'css sass',
  'thought leadership',
  'revenue management',
  'craft cms',
  'lms',
  'dutch',
  'nederlands',
  'klantenservice',
  'netherlands',
  'analytical skills',
  'direct response copywriter',
  'sem',
  'direct response',
  'conversion',
  'api integrations',
  'funnels',
  'landing page',
  'node node front end',
  'vsl',
  'testing code',
  'messaging',
  'sales page',
  'node ios',
  'node android',
  'native',
  'games',
  'video sales letter',
  'integrations',
  'member services',
  'react js',
  'executive support',
  'seo principles',
  'debugging',
  'browser testing',
  'rest assured',
  'dev tools',
  'systems programming',
  'cto',
  'testng',
  'selenium',
  'cucumber',
  'ci cd pipelines',
  'software quality assurance',
  'code review',
  'set',
  'react node mongodb',
  'full stack software development',
  'microservices architectures',
  'cloud computing',
  'architect',
  'arificial intelligence',
  'problem solving',
  'linux systems',
  'travis',
  'email automation',
  'data analysis',
  'browser extensions',
  'unity3d',
  'jest',
  'math',
  'human resources',
  'people',
  'performance',
  'organizational development',
  'hr',
  'shopware',
  'spring boot',
  'slack',
  'b2c',
  'imagify',
  'node mongodb',
  'retail',
  'npm',
  'craftcms',
  'cms',
  'virtual reality',
  'review',
  'business development',
  'director',
  'node community building',
  'python django',
  'node css',
  'writing skills',
  'gatsby',
  'storybook',
  'remote marketing',
  'mentorship',
  'content writing',
  'data engineer',
  'customer service representative',
  'travel tech',
  'phpunit',
  'api design',
  'product backlog',
  'tech knowledges',
  'business oriented',
  'marketing knowledges',
  'mobile architecture',
  'objective c language',
  'ios ecosystem xcode',
  'tooling',
  'leadership',
  'pytest',
  'feature testing',
  'dynamodb',
  'sqs',
  'functional programming',
  'data analyst',
  'analyze bugs',
  'platform api',
  'scripting language',
  'linux system development',
  'ci cd tools',
  'quality control',
  'raheem',
  'ux research',
  'chief of staff',
  'partnerships',
  'marketing communications',
  'adtech',
  'oss',
  'diabetes',
  'lead engineer',
  'remote',
  'pm',
  'websockets',
  'telemetry',
  'relayjs',
  'google ad campaign',
  'web traffic',
  'email campaigns',
  'membership business',
  'leads',
  'telegram',
  'chatbots',
  'bets',
  'street',
  'wall',
  'helm',
  'cordova',
  'typecript',
  'biotech',
  'empathy',
  'product analyst',
  'social feed',
  'social graph',
  'remotely',
  'application development',
  'configuration management',
  'helpscout',
  'web design',
  'graphic designer',
  'geographical knowlege',
  'geo',
  'entry level',
  'audio',
  'usertesting',
  'data tagging',
  'data collection',
  'technical troubleshooting',
  'grpc',
  'sdr',
  'cx',
  'd2c',
  'rxswift',
  'strategy',
  'information architecture',
  'consultant',
  'alpinejs',
  'growth hacker',
  'saas sales',
  'inbound sales',
  'data visualizations',
  'responsive design',
  'pthyon',
  'socal media',
  'mobile growth',
  'reactivecocoa',
  'alert response',
  'paid marketing',
  'full remote',
  'unix',
  'make',
  'haproxy',
  'openresty',
  'lua',
  'pl python',
  'pl pgsql',
  'nomad',
  'poetry',
  'mypy',
  'apollo',
  'postgraphile',
  'remote react javascript jobs',
  'elixir go ruby python remote jobs',
  'aws linux remote jobs scm',
  'prometheus',
  'meteor',
  'structured data',
  'relational databases',
  'json',
  'enterprise workflow',
  'software product',
  'amazon web services',
  'product specialist',
  'back end engineer',
  'hosting',
  'nodejs 5',
  'tester',
  'test frameworks',
  'grafana',
  'kibana',
  'remote health',
  'sales leads',
  'leadership development',
  'integrity',
  'vetting',
  'professional development',
  'cake',
  'codeigniter',
  'html5 scss',
  'brain tree',
  'stripe',
  'html5 jquery',
  'phone',
  'customer',
  'client',
  'weather',
  'metabase',
  'maps',
  'product design',
  'lean',
  'technical project manager',
  'b2b2c',
  'data warehousing',
  'gopher',
  'mobile retail',
  'platform',
  'mobile technology',
  'scaleway',
  'matomo',
  'varnish',
  'marketing design',
  'sketch app',
  'csv',
  'google tag manager',
  'mobile application developer',
  'buddypress',
  'buddyboss',
  'support engineer',
  'happiness engineer',
  'cicd',
  'management engineering remote',
  'art direction',
  'periscope',
  'mode',
  'looker',
  'tealium',
  'tableau',
  'adobe analytics',
  'appcenter',
  'frontend js',
  'climate',
  'geospatial',
  'wpcli',
  'social',
  'twig',
  'digital by default',
  'appointment setter',
  'setter',
  'setting',
  'salespeople',
  'salesperson',
  'closing',
  'wordpress github',
  'venture capital',
  'seed funded',
  'logging',
  'xpath',
  'administration',
  'email support',
  'phone support',
  'new zealand',
  'micro services',
  'open banking',
  'inbound',
  'technical editing',
  'technical writing',
  'dockers',
  'db',
  'metrics',
  'python3',
  'b2b software',
  'smart grids',
  'energy',
  'cleantech',
  'electric vehicles',
  'flask',
  'vc',
  'venture',
  'funding',
  'capital',
  'investing',
  'b2b saas',
  'startups',
  'enterprise software',
  'closer',
  'communication',
  'content strategy',
  'data orchestration',
  'oop',
  'ux design',
  'vwo',
  'optimizely',
  'test automation',
  'net core',
  'product integration',
  'project leader',
  'photoeditor',
  'latex',
  'science',
  'software design',
  'ui design',
  'data pipe',
  'google play store',
  'shell',
  'cloudformation',
  'play store',
  'app store',
  'google',
  'apple',
  'mobile app development',
  'photo editing',
  'integration',
  'content promotion',
  'twitter ads',
  'facebook ads',
  'reactnative',
  'app store optimization',
  'apple app store',
  'managmenet',
  'bootstrapped',
  'electron',
  'partneships',
  'reverse engineering',
  'crawling',
  'web scraping',
  'adobe photoshop',
  'javascript jquery',
  'jira',
  'productivity software',
  'process management',
  'software as a service',
  'low code',
  'no code',
  'systems engineer',
  'google developer account',
  'talent development',
  'coaching',
  'blogs',
  'law',
  'immigration',
  'legal',
  'full stack developer',
  'senior developer',
  'test',
  'xcode',
  'app store guidelines',
  'app publishing',
  'apple developer account',
  'ui ux',
].sort();

export const filterSuggestions = (q = '') => {
  q = q.toLowerCase();
  const sugg = !q
    ? [...suggestions]
    : [...suggestions].filter(s => s.includes(q));

  if (sugg.length > 10) {
    sugg.length = 10;
  }
  return sugg.map(label => ({ label: label }));
};
