import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { suggestions } from '../utility/suggestionData';

const fSuggestions = [...suggestions];
fSuggestions.length = 100;

const FooterLinks = () => (
  <div className={`FooterLinks ${isMobile && 'isMobile'}`}>
    {fSuggestions.map(suggestion => (
      <Link
        key={suggestion}
        to={`/search/${suggestion.replaceAll(' ', '-')}`}
        className="noLink"
      >
        {suggestion}
      </Link>
    ))}
  </div>
);

export default FooterLinks;
