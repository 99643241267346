import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BTIcon } from '../svg/BTIcon.svg';
import { ReactComponent as RJIcon } from '../svg/categories/RJIcon.svg';
import { ReactComponent as SDIcon } from '../svg/categories/SDIcon.svg';
import { ReactComponent as CSIcon } from '../svg/categories/CSIcon.svg';
import { ReactComponent as MJIcon } from '../svg/categories/MJIcon.svg';
import { ReactComponent as DJIcon } from '../svg/categories/DJIcon.svg';
import { ReactComponent as NTIcon } from '../svg/categories/NTIcon.svg';
import { ReactComponent as FJIcon } from '../svg/categories/FJIcon.svg';
import { ReactComponent as TRIcon } from '../svg/categories/TRIcon.svg';

export const options = [
  {
    label: 'Remote Jobs',
    link: 'remote-jobs',
    Icon: RJIcon,
  },
  {
    label: 'Software Development',
    link: 'software-development',
    Icon: SDIcon,
  },
  {
    label: 'Customer Support',
    link: 'customer-support',
    Icon: CSIcon,
  },
  {
    label: 'Marketing Jobs',
    link: 'marketing-jobs',
    Icon: MJIcon,
  },
  {
    label: 'Design Jobs',
    link: 'design-jobs',
    Icon: DJIcon,
  },
  {
    label: 'Non-tech Jobs',
    link: 'nontech-jobs',
    Icon: NTIcon,
  },
  {
    label: 'Freelancer Jobs',
    link: 'freelancer-jobs',
    Icon: FJIcon,
  },
  {
    label: 'Top Remote Companies',
    link: 'top-remote-companies',
    Icon: TRIcon,
  },
];

export const getCategoryName = link => {
  const fOptions = options.filter(o => o.link === link);
  if (fOptions.length !== 0) return fOptions[0].label;
  return link;
};

const Categories = ({ category }) => (
  <div id="Categories" className="Categories">
    {options.map(({ label, link, Icon }) => (
      <Link
        key={link}
        to={`/category/${link}`}
        className="SingleCategory noLink"
      >
        <div className="body">
          <div className="details">
            <Icon />
            <p>{label}</p>
          </div>

          <div className="triangle">{link === category && <BTIcon />}</div>
        </div>
      </Link>
    ))}
  </div>
);

export default Categories;
