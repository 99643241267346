import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AppBody from './AppBody';
import '../scss/index.css';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={AppBody} />
          <Route exact path="/job/:cJobId" component={AppBody} />
          <Route exact path="/category/:cCategoryId" component={AppBody} />
          <Route exact path="/search/:cSearchQ" component={AppBody} />
          <Route exact path="/company/:cCompanyId" component={AppBody} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
