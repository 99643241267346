import React from 'react';
import { Link } from 'react-router-dom';
import { onClickNoAction } from '../utility/utility';
import { getCategoriesArray } from '../utility/utility';

const CategoriesBody = ({ categories = [], maxLength = 6 }) => (
  <div className="categoriesBody">
    {getCategoriesArray(categories, maxLength).map(category => (
      <Link
        key={category}
        to={`/search/${category.replaceAll(' ', '-')}`}
        className="noLink"
        onClick={onClickNoAction}
      >
        <p>{category}</p>
      </Link>
    ))}
  </div>
);

export default CategoriesBody;
