const TITLE = 'Try Remote Jobs: Programming, Freelancers, Sales and many';
const HEADER = 'Try Remote Jobs';
const TITLE_POSTFIX = '| Try Remote Jobs';

export const createMeta = ({
  isCompany = false,
  isJobPage = false,
  job = {},
  company = false,
  category = '',
  searchString = '',
}) => {
  // Header showed in the top of jobs
  let header = `${HEADER}`;
  // Page title
  let title = `${TITLE}`;

  if (isCompany) {
    // it is activated for remote company pages
    header = 'Remote Companies';
    title = 'Remote Companies';
  } else if (isJobPage) {
    // it is activated for job detail pages
    header = '';
    title = `Remote ${job.title} at ${job.com_name} ${TITLE_POSTFIX}`;
  } else if (company) {
    // it is activated when a company is selected
    const com_name = company.com_name || '';
    header = `Remote Jobs at ${com_name}`;
    title = `Remote Jobs at ${com_name} ${TITLE_POSTFIX}`;
  } else if (category) {
    // it is activated when we select any category except company page
    header = category;
    title = `${category} ${TITLE_POSTFIX}`;
  } else if (searchString) {
    // it is activated when we search
    header = `${searchString} jobs`;
    title = `${searchString} jobs ${TITLE_POSTFIX}`;
  }

  return { metaParts: [], header, title };
};

// const getHtmlText = html => {
//   const div = document.createElement('div');
//   div.innerHTML = html;
//   return div.textContent || div.innerText || '';
// };
