import React, { Component } from 'react';
import AutoComplete from 'react-autocomplete';
import { isMobile } from 'react-device-detect';

import HeroHomeImg from '../images/HeroHome.jpg';
import HeroHomeSingleImg from '../images/HeroHomeSingle.jpg';
import CurveTop from '../images/CurveTop.png';
import { ReactComponent as SearchIcon } from '../svg/SearchIcon.svg';
import { filterSuggestions } from '../utility/suggestionData';
import { removeExtraCharacter } from '../utility/utility';

class HeroHome extends Component {
  state = { menuOpen: false };

  onChange = e => {
    const value = removeExtraCharacter(e.target ? e.target.value : e);
    this.props.onSearchUpdate(value, !e.target);
  };

  onMenuVisibilityChange = menuOpen => this.setState({ menuOpen });

  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.props.onSearchUpdate(this.props.q, true);
    }
  };

  render() {
    const { q, sJobId } = this.props;
    const items = filterSuggestions(q);

    return (
      <div
        className="heroHome"
        style={{
          backgroundImage: isMobile
            ? null
            : `url(${sJobId ? HeroHomeSingleImg : HeroHomeImg})`,
        }}
      >
        {!isMobile && <img className="CurveTop" src={CurveTop} alt="#" />}{' '}
        <p className="heroText">{`Can't find jobs locally?`}</p>
        <p className="heroText">{`Try remote jobs!`}</p>
        <div className="autoComplete">
          <AutoComplete
            getItemValue={item => item.label}
            items={items}
            renderItem={(item, isHighlighted) => (
              <div key={item.label} className="item">
                <div className="opt">{item.label}</div>
              </div>
            )}
            renderInput={props => (
              <div
                className={`inputHolder ${this.state.menuOpen &&
                  items.length &&
                  'menuOpen'}`}
              >
                <SearchIcon />
                <input placeholder="Find Remote Jobs" {...props} />
              </div>
            )}
            value={q}
            onChange={this.onChange}
            onSelect={this.onChange}
            inputProps={{ onKeyDown: this.onKeyDown }}
            onMenuVisibilityChange={this.onMenuVisibilityChange}
            menuStyle={{
              background: 'transparent',
              zIndex: 2,
              position: 'absolute',
              top: isMobile ? 30 : 40,
              left: 0,
            }}
          />
        </div>
      </div>
    );
  }
}

export default HeroHome;
