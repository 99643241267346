export const BASE_URL = 'https://www.tryremotejobs.com';

export const isMobile =
  (window.navigator.maxTouchPoints || 'ontouchstart' in document) === 1;

export const validURL = str => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const removeExtraCharacter = (str = '') =>
  str.replaceAll(/[^a-z\d\s]+/gi, '').toLowerCase();

export const createRouteIdName = (id, name) =>
  `${removeExtraCharacter(name)
    .trim()
    .replace(/\s\s+/g, ' ')
    .replaceAll(' ', '-')}-${id}`;

export const getIdFromRoute = (name = '') => {
  const parts = name
    .trim()
    .split('-')
    .filter(n => n !== '');
  if (parts.length) {
    return parts[parts.length - 1];
  }
  return '';
};

export const onClickNoAction = (e, a, b) => e.stopPropagation();

export const getCategoriesArray = (categories = [], maxLength = 6) => {
  const cat = categories.filter(c => !!c);

  if (cat.length > maxLength) {
    cat.length = maxLength;
  }
  return [...new Set(cat)];
};

export const getCategories = (categories = [], maxLength = 6) => {
  const cat = getCategoriesArray(categories, maxLength);
  return cat.join(',');
};
