import React from 'react';
import { isMobile } from 'react-device-detect';
import CategoriesBody from './CategoriesBody';
import { BASE_URL } from '../utility/utility';

const CompanyBody = ({ company, history }) => {
  const comLink = company.link_company.replace(BASE_URL, '');

  return (
    <div
      className={`jobBody job_${company.id} companyBody`}
      onClick={() => history.push(comLink)}
    >
      <div className="logo">
        <div className="container">
          <img src={company.link_com_logo} alt="#" />
        </div>
      </div>
      <div className="desc">
        <p className="title">{company.com_name}</p>
        <p className="post">{`${company.total} job${
          company.total > 1 ? 's' : ''
        }`}</p>
        {isMobile && (
          <CategoriesBody
            categories={company.array_categories}
            maxLength={10}
          />
        )}
      </div>
      <div className="separator" />
      {!isMobile && (
        <CategoriesBody categories={company.array_categories} maxLength={10} />
      )}
    </div>
  );
};

export default CompanyBody;
