import React from 'react';

const Loader = ({ className }) => (
  <div className={`trj-loader ${className}`} key={0}>
    <div className="loader-body" key={0}>
      <div className="loader-b" />
    </div>
  </div>
);

export default Loader;
