import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import CurveBottom from '../images/CurveBottom.png';
import { validateEmail } from '../utility/utility';
import API from '../utility/api';
import { ReactComponent as ClearIcon } from '../svg/times.svg';

const START_FOOTER = 600;
let removeFooter = true;

const sendEmail = () => {
  const mail = document.getElementById('subscription').value;
  if (!validateEmail(mail)) {
    alert("That's not a valid email address!");
    return;
  }
  const a = Math.floor(Math.random() * 4) + 1;
  const b = Math.floor(Math.random() * 4) + 1;
  const ans = prompt(
    `To avoid spam sign ups, please answer: What is ${a} + ${b}?`
  );

  if (ans !== `${a + b}`) {
    alert('The answer is not right. Please try again');
    return;
  }
  try {
    API.get('subscription.php', { mail });
  } catch (e) {
    console.error("Can't add subscription", e);
  }

  alert(
    'Subscriber has been added successfully. We will start sending you your email alerts.'
  );
};

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      removeFooter,
      scrollHeight: 0,
      scrollStyle: {},
    };
    this.footerRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.updateScrollHeight();
  }

  componentDidUpdate() {
    this.updateScrollHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  updateScrollHeight = () => {
    let scrollHeight = this.footerRef.current.scrollHeight;

    if (isNaN(scrollHeight)) {
      scrollHeight = 0;
    }
    if (this.state.scrollHeight !== scrollHeight) {
      this.setState({ scrollHeight });
    }
  };

  handleScroll = event => {
    const { scrollHeight, scrollStyle, removeFooter } = this.state;
    if (!removeFooter) {
      return;
    }
    let bottom = (window.pageYOffset - START_FOOTER - scrollHeight) / 2;
    if (bottom > 0 || isMobile || this.isEmptyDivInViewport()) {
      bottom = 0;
    }
    if (scrollStyle.bottom !== bottom) {
      this.setState({ scrollStyle: { bottom } }, this.updateScrollHeight);
    }
  };

  isEmptyDivInViewport = (offset = 0) => {
    if (!this.emptyDiv) return false;
    const top = this.emptyDiv.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  };

  removeDisable = () => {
    removeFooter = false;
    this.setState({ removeFooter: false });
  };

  render() {
    const { removeFooter, scrollHeight, scrollStyle } = this.state;

    return (
      <>
        {removeFooter && (
          <div
            ref={ref => (this.emptyDiv = ref)}
            style={{
              position: 'absolute',
              width: '100vw',
              height: scrollHeight,
            }}
          />
        )}
        <div
          className="footer"
          ref={this.footerRef}
          style={removeFooter ? scrollStyle : { position: 'relative' }}
        >
          <img className="CurveBottom" src={CurveBottom} alt="#" />
          <div className="details">
            <div className="lPart">
              <p>Grow Remotely and get regular updates of all remote jobs</p>
              <p>alone with 27,784+ others</p>
            </div>
            <div className="rPart">
              <input placeholder="Email.." id="subscription" />
              <button onClick={sendEmail}>SUBSCRIBE</button>
            </div>

            {removeFooter && !isMobile && (
              <div className="close">
                <button onClick={this.removeDisable}>
                  <ClearIcon />
                </button>
              </div>
            )}
          </div>

          {removeFooter && isMobile && (
            <div className="close">
              <button onClick={this.removeDisable}>
                <ClearIcon />
              </button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Footer;
