import React, { Component } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { validURL, onClickNoAction, BASE_URL } from '../utility/utility';

import { ReactComponent as USOnlyIcon } from '../svg/USOnlyIcon.svg';
import { ReactComponent as WorldWideIcon } from '../svg/WorldWideIcon.svg';
import { ReactComponent as ArrowUp } from '../svg/arrow-up.svg';
import { ReactComponent as ArrowDown } from '../svg/arrow-down.svg';
import CategoriesBody from './CategoriesBody';

class SingleJobBody extends Component {
  state = { hovered: false };

  updateHover = hovered => this.setState({ hovered });

  render() {
    const { job, index = 0, isSelected, onJobClick } = this.props;
    const isExpired = job.status === '0' || job.expired === '1';

    const applyValidUrl = validURL(job.apply);
    const showApplyOnHeader = !isExpired && applyValidUrl && this.state.hovered;
    const comLink = job.link_company.replace(BASE_URL, '');

    const applyLink =
      applyValidUrl &&
      `${job.link_job.replace(BASE_URL, '').replace('/job/', '/apply/')}`;

    return (
      <div
        className={`single-job-container ${isSelected && 'selectedJob'}`}
        key={index}
      >
        <div
          className={`jobBody job_${index % 3}`}
          onClick={() => onJobClick(job)}
          onMouseEnter={() => this.updateHover(true)}
          onMouseLeave={() => this.updateHover(false)}
        >
          <div className="logo">
            <div className="container">
              <img src={job.link_com_logo} alt="#" />
            </div>
          </div>
          <div className="desc">
            <div className="start">
              {/* <p className="source">{job.provider}</p> */}
              {/* {index % 2 === 0 && <p className="verified">{'VERIFIED'}</p>} */}
            </div>
            <Link to={comLink} onClick={onClickNoAction} className="noLink">
              <p className="company">{job.com_name}</p>
            </Link>
            <p className="title">{job.title}</p>
            <div className="end">
              <div className="location">
                {job.from_usa === '1' && <USOnlyIcon />}
                {job.is_remote === '1' && <WorldWideIcon />}
                <p>{job.location}</p>
              </div>
              {!isExpired && job.expired_at && (
                <p className="date">
                  {moment(job.expired_at)
                    .fromNow()
                    .replaceAll(' a ', ' 1 ')
                    .replaceAll('ago', '')
                    .replaceAll('in ', '')
                    .trim()}
                </p>
              )}
              {isExpired && <p className="date expired">(Expired)</p>}
            </div>
            {isMobile && <CategoriesBody categories={job.array_categories} />}
          </div>
          <div className="separator" />
          {!isMobile && (
            <>
              <CategoriesBody categories={job.array_categories} />
              <div className="separator separator2" />
            </>
          )}
          <div className={`apply ${isSelected && 'isSelected'}`}>
            {showApplyOnHeader ? (
              <a href={applyLink} target="_blank" className="noLink">
                <button>Apply Now</button>
              </a>
            ) : isSelected ? (
              <ArrowUp />
            ) : (
              <ArrowDown />
            )}
          </div>
        </div>
        <div className="jobDetails">
          <div
            className="sjDesc"
            dangerouslySetInnerHTML={{ __html: job.description }}
          />
          {isExpired ? (
            <div className="expired_job">
              <p>This job is already expired</p>
            </div>
          ) : (
            job.apply &&
            (applyValidUrl ? (
              <div className="applyForPost">
                <a href={applyLink} target="_blank" className="noLink">
                  <button>Apply for this job</button>
                </a>
              </div>
            ) : (
              <>
                <p className="jobHeader">How to apply</p>
                <div
                  className="sjHow"
                  dangerouslySetInnerHTML={{ __html: job.apply }}
                />
              </>
            ))
          )}
        </div>
      </div>
    );
  }
}

export default SingleJobBody;
