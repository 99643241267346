import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';

import { getCategoryName } from '../components/Categories';
import {
  Loader,
  Header,
  HeroHome,
  Categories,
  Footer,
  CompanyBody,
  JobBody,
  PartTitle,
} from '../components';
import HeroHomeImg from '../images/HeroHome.jpg';
import HeroHomeSingleImg from '../images/HeroHomeSingle.jpg';
import API from '../utility/api';
import { getIdFromRoute, BASE_URL } from '../utility/utility';
import { createMeta } from '../utility/metaUtility';

const DEFAULT_LIMIT = 30;

const initialState = {
  metaProps: {},
  title: 'Try Remote Jobs: Programming, Freelancers, Sales and many',
  initiated: false,
  companyId: '',
  jobIds: [],
  category: '',
  searchString: '',

  q: '',
  promoteJobs: [],
  jobs: [],
  companies: [],
  relatedJobs: [],
  header: '',
  page: 1,
  loading: true,
  pageLoading: true,
  hasMore: false,
  isCompany: false,
  isJobPage: false,
  noResult: false,
  noResultText: 'No result found. Please click for some exciting jobs',
};

class AppBody extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.pathname = '';
  }

  componentDidMount() {
    this.catTop = document
      .getElementById('Categories')
      .getBoundingClientRect().top;
    this.locationChange();
  }

  componentDidUpdate(props) {
    if (this.pathname !== this.props.location.pathname) {
      this.locationChange();
    }
  }

  onSearchUpdate = (q, activeSearch) => {
    this.setState({ q }, () => {
      if (activeSearch && q.trim()) {
        this.props.history.replace(`/search/${q.trim().replaceAll(' ', '-')}`);
      }
    });
  };

  onJobClick = job => {
    const { id, link_job } = job;
    let { jobIds } = this.state;
    const isSelected = jobIds.includes(id);
    jobIds = isSelected ? jobIds.filter(job => job !== id) : [id, ...jobIds];
    this.setState({ jobIds }, () => {
      if (!isSelected && this.props.location.pathname !== `/job/${id}`) {
        this.props.history.push(link_job.replace(BASE_URL, ''));
      }
    });
  };

  getMatchParams = path => {
    let jobId = '',
      companyId = '',
      category = '',
      searchString = '',
      q = '';
    if (path.startsWith('/category/')) {
      category = path.replace('/category/', '').replace('/', '');
    } else if (path.startsWith('/company/')) {
      companyId = getIdFromRoute(
        path.replace('/company/', '').replace('/', '')
      );
    } else if (path.startsWith('/job/')) {
      jobId = getIdFromRoute(path.replace('/job/', '').replace('/', ''));
    } else if (path.startsWith('/search/')) {
      searchString = path
        .replace('/search/', '')
        .replace('/', '')
        .replace('-', ' ');
      q = searchString;
    }
    return { jobId, companyId, category, searchString, q };
  };

  locationChange = () => {
    this.pathname = this.props.location.pathname;
    const newParams = this.getMatchParams(this.pathname);
    const { initiated, companyId, jobIds, category, searchString } = this.state;
    // console.log(initiated, newParams.jobId);
    if (initiated && jobIds.includes(newParams.jobId)) {
      this.setState({ initiated: true });
    } else if (
      !initiated ||
      !jobIds.includes(newParams.jobId) ||
      category !== newParams.category ||
      searchString !== newParams.searchString ||
      companyId !== newParams.companyId
    ) {
      if (initiated) {
        window.scrollTo({ behavior: 'smooth', top: this.catTop });
      }

      this.setState(
        {
          ...initialState,
          ...newParams,
          initiated: true,
          isJobPage:
            newParams.jobId &&
            !(
              newParams.searchString ||
              newParams.category ||
              newParams.companyId
            ),
          jobIds: newParams.jobId ? [newParams.jobId] : [],
          isCompany: newParams.category === 'top-remote-companies',
        },
        () => this.loadData()
      );
    }
  };

  loadData = async () => {
    const {
      searchString,
      category,
      jobIds,
      companyId,
      isCompany,
      isJobPage,
      page,
      jobs,
      companies,
    } = this.state;

    const metaP = {
      pathname: this.props.location.pathname,
    };

    try {
      if (isCompany) {
        const comResponses = await API.get('company.php', {
          limit: DEFAULT_LIMIT,
          page,
        });
        const newCompanies =
          page === 1
            ? [...comResponses.companies]
            : [...companies, ...comResponses.companies];
        metaP.isCompany = true;
        this.setState({
          loading: false,
          pageLoading: false,
          hasMore: comResponses.hasMore,
          companies: newCompanies,
          noResult: newCompanies.length === 0,
          metaProps: createMeta(metaP),
        });
      } else if (isJobPage) {
        const detailResponses = await API.get('job_details.php', {
          id: jobIds[0],
        });
        const job = detailResponses.details || null;

        metaP.isJobPage = true;
        metaP.job = job;

        this.setState({
          loading: false,
          pageLoading: false,
          hasMore: false,
          jobs: job ? [job] : [],
          relatedJobs: detailResponses.related_jobs || [],
          noResult: job === null,
          metaProps: createMeta(metaP),
        });
      } else {
        const jobResponses = await API.get('jobs.php', {
          limit: DEFAULT_LIMIT,
          q: searchString,
          category,
          company: companyId,
          page,
        });
        const promoteJobs =
          page === 1 ? jobResponses.promote_jobs : this.state.promoteJobs;
        const newJobs =
          page === 1 ? [...jobResponses.jobs] : [...jobs, ...jobResponses.jobs];
        let title = initialState.title;
        let header = 'Try Remote Jobs';
        let noResultText = initialState.noResultText;
        const company = companyId && jobResponses.company;

        metaP.total = jobResponses.total;
        metaP.company = company;
        metaP.jobs = newJobs;
        if (company) {
          if (!newJobs.length) {
            noResultText = `There are currently no vacancy in ${company.com_name}. Please click for some exciting jobs`;
          }
        } else if (category) {
          metaP.category = getCategoryName(category);
        } else if (searchString) {
          metaP.searchString = searchString;
        }

        this.setState({
          loading: false,
          pageLoading: false,
          hasMore: jobResponses.hasMore,
          jobs: newJobs,
          promoteJobs: companyId ? [] : promoteJobs,
          title,
          header,
          noResult: newJobs.length === 0,
          noResultText,
          metaProps: createMeta(metaP),
        });
      }
    } catch (e) {
      console.error('Problem loading data', e);
      this.setState({
        loading: false,
        pageLoading: false,
        hasMore: false,
      });
    }
  };

  loadPage = () => {
    const { loading, pageLoading, hasMore, page } = this.state;
    if (loading || pageLoading || !hasMore) {
      return;
    }
    this.setState({ page: page + 1, pageLoading: true }, () => this.loadData());
  };

  singleJob = (job, index) => (
    <JobBody
      {...this.props}
      key={job.id}
      job={job}
      index={index}
      isSelected={this.state.jobIds.includes(job.id)}
      onJobClick={this.onJobClick}
    />
  );

  singleCompany = (company, index) => (
    <CompanyBody
      {...this.props}
      key={company.id}
      company={company}
      index={index}
    />
  );

  render() {
    const {
      loading,
      hasMore,
      isJobPage,
      promoteJobs,
      jobs,
      companies,
      relatedJobs,
      noResult,
      noResultText,
      metaProps,
    } = this.state;

    return (
      <div className={`AppBody  ${isMobile && 'isMobile'}`}>
        <Helmet title={metaProps.title}>
          {metaProps.metaParts}
          {metaProps.script && (
            <script type="application/ld+json">{metaProps.script}</script>
          )}
        </Helmet>

        <div
          style={{
            backgroundImage: !isMobile
              ? null
              : `url(${isJobPage ? HeroHomeSingleImg : HeroHomeImg})`,
            backgroundSize: 'cover',
          }}
        >
          <Header />
          <HeroHome
            {...this.props}
            {...this.state}
            onSearchUpdate={this.onSearchUpdate}
          />
        </div>
        <Categories {...this.props} {...this.state} />
        <div className="body-container">
          <div className="body-center">
            {loading ? (
              <Loader className="loader" />
            ) : (
              <>
                {promoteJobs.length > 0 && (
                  <div className="promoted">
                    <div className="title-header">
                      <PartTitle title="Promoted Remote Jobs" />
                      <a
                        href="mailto:sales@tryremotejobs.com?subject=Advertise on Try Remote Jobs&body=We would like to apply for advertisement on Try Remote Jobs."
                        target="blank"
                        className="noLink"
                      >
                        Advertise with us: sales@tryremotejobs.com
                      </a>
                    </div>
                    {promoteJobs.map(this.singleJob)}
                  </div>
                )}
                <InfiniteScroll
                  threshold={400}
                  loadMore={this.loadPage}
                  hasMore={hasMore}
                  style={{ width: '100%' }}
                  loader={<Loader key="loader" />}
                >
                  <>
                    {metaProps.header && <PartTitle title={metaProps.header} />}
                    {jobs.map(this.singleJob)}
                    {companies.map(this.singleCompany)}
                    {noResult && (
                      <div className="no-result">
                        <Link to="/" className="noLink">
                          {noResultText}
                        </Link>
                      </div>
                    )}
                  </>
                </InfiniteScroll>
                {relatedJobs.length > 0 && (
                  <div className="jobs">
                    <PartTitle title="Related Jobs" />
                    {relatedJobs.map(this.singleJob)}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {!loading && <Footer />}
      </div>
    );
  }
}

export default AppBody;
