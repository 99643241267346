import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Twitter } from '../svg/LTwitter.svg';
import { ReactComponent as Facebook } from '../svg/LFacebook.svg';
import { ReactComponent as Linkedin } from '../svg/LLinkedin.svg';

const Header = () => (
  <div className="header">
    <div className="lPart"></div>
    <Link className="noLink headerText" to="/">
      <h1>
        <span>Try</span>
        <span className="middle">Remote</span>
        <span>Jobs</span>
        <span className="r">®</span>
      </h1>
    </Link>
    <div className="rPart">
      <a
        className="noLink icon"
        href="https://twitter.com/tryremotejobs"
        target="blank"
      >
        <Twitter />
      </a>
      <a
        className="noLink icon"
        href="https://www.facebook.com/tryremotejobs"
        target="blank"
      >
        <Facebook />
      </a>
      <a
        className="noLink icon"
        href="https://www.linkedin.com/company/tryremotejobs/"
        target="blank"
      >
        <Linkedin />
      </a>
    </div>
  </div>
);

export default Header;
