import { create } from 'apisauce';
import qs from 'qs';
import { BASE_URL } from './utility';

const headers = () => ({
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
});

const API_PREFIX = `${BASE_URL}/api/`;

const queryStringify = params => {
  Object.keys(params).forEach(prop => {
    if (!params[prop]) {
      params[prop] = undefined;
    }
  });
  return qs.stringify(params, { indices: false });
};

export class Api {
  constructor() {
    this.baseUrl = API_PREFIX;
    this.api = create({
      baseURL: API_PREFIX,
      paramsSerializer: params => queryStringify(params),
      headers,
    });
  }

  getBaseUrl = () => this.baseUrl;

  addMonitor = monitorFn => {
    this.api.addMonitor(monitorFn);
  };

  getDataFromResponse = response => {
    if (response.ok && response.status >= 200 && response.status < 300) {
      return response.data;
    }

    const error = new Error(this.createErrorMessage(response));
    error.status = response.status;
    error.requestId = response.headers && response.headers['x-request-id'];
    error.data = response.data;
    throw error;
  };

  createErrorMessage = response => {
    let message = '';
    if (response.data && response.data.message) {
      message =
        typeof response.data.message === 'string'
          ? response.data.message
          : JSON.stringify(response.data.message);
    } else if (typeof response.data === 'string') {
      message = `${response.problem} : ${response.data}`;
    } else {
      message = response.problem;
    }

    return message;
  };

  get = async (uri, params) => {
    try {
      const response = await this.api.get(uri, params);
      return this.getDataFromResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  post = async (uri, params) => {
    try {
      const response = await this.api.post(uri, params);
      return this.getDataFromResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  put = async (uri, params) => {
    try {
      const response = await this.api.put(uri, params);
      return this.getDataFromResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  patch = async (uri, params) => {
    try {
      const response = await this.api.patch(uri, params);
      return this.getDataFromResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  delete = async uri => {
    try {
      const response = await this.api.delete(uri);
      return this.getDataFromResponse(response);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
}

const defaultAPI = new Api();
export default defaultAPI;
